body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
    --jumbotron-padding-y: 3rem;
}

.jumbotron {
    padding-top: 3rem;
    padding-top: var(--jumbotron-padding-y);
    padding-bottom: 3rem;
    padding-bottom: var(--jumbotron-padding-y);
    margin-bottom: 0;
    background-color: #fff !important;
}
@media (min-width: 768px) {
    .jumbotron {
        padding-top: calc(3rem * 2);
        padding-top: calc(var(--jumbotron-padding-y) * 2);
        padding-bottom: calc(3rem * 2);
        padding-bottom: calc(var(--jumbotron-padding-y) * 2);
    }
}

.jumbotron p:last-child {
    margin-bottom: 0;
}

.jumbotron-heading {
    font-weight: 300;
}

.jumbotron .container {
    max-width: 40rem;
}

footer {
    padding-top: 3rem;
    padding-bottom: 3rem;
}

footer p {
    margin-bottom: 0.25rem;
}

.box-shadow {
    box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.05);
}

.setting-sidebar {
    position: fixed;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    background-color: #007bff;
    width: 80px;
    height: 80px;
    right: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    box-shadow: 0px 0px 5px 0px rgba(154, 154, 154, 0.54);
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    z-index: 1;
  }
